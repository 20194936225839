<template>
  <div id="tradingTutorial">
    <div class="content_box">
      <div class="inner_max">
        <h3 v-html="proTpPremiumReplacerPartial($t('menu.MARKET_MASTERS'), 2)"></h3>
        <VimeoVideoLink />
      </div>
    </div>
    <LockPremiumTradingTools v-if="lockPremiumTools" />
  </div>
</template>

<script>
import asicMixin from '@/mixins/proTools/asicReplacer';
import lockPremiumToolsMixin from '@/mixins/proTools/lockPremiumTools';
import VimeoVideoLink from '@/components/video/VimeoVideoLink';
import LockPremiumTradingTools from '@/components/LockPremiumTradingTools';

export default {
  name: 'MarketMasters',
  mixins: [lockPremiumToolsMixin, asicMixin],
  components: { VimeoVideoLink, LockPremiumTradingTools },
  computed: {
    lang: {
      get() {
        return this.$store.state.common.lang
      },
      set(value) {
        return this.$store.commit('common/setLang', value)
      },
    },
  },
};
</script>
