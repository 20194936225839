<template>
  <div id="tradingTool">
    <div :class="{ content_box: showClass }">
      <div :class="{ inner_max: showClass }">
        <idPoaLimit ref="idPoaLimit">
          <!-- apply for tradingTools -->
          <div v-if="restrictTradingTool">
            <!-- title -->
            <h3 v-html="proTpPremiumReplacerPartial($t('menu.tradingTools'), 2)"></h3>
            
            <!-- gain access to pro trade tools -->
            <el-card class="top_box">
              <div class="deposit" v-if="needDeposit && needUnlock">
                <div class="tip" v-html="$t('tradingTools.condition.needDeposit')"></div>
                <div class="btn_box">
                  <router-link
                    :to="{ name: 'depositFunds' }"
                    class="el-button el-button--primary"
                    data-testid="depositNow"
                  >
                    {{ $t('tradingTools.condition.depositNow') }}
                  </router-link>
                </div>
              </div>
              <div class="check" v-else>
                <el-form :model="form" :rules="rule" ref="tradingToolForm">
                  <p v-html="proTpPremiumReplacerPartial($t('tradingTools.condition.congratulations'), 1)"></p>
                  <el-form-item prop="checked">
                    <el-checkbox v-model="form.checked" data-testid="checkbox">
                      <i18n :path="'tradingTools.condition.needTnc'">
                        <template v-slot:link>
                          <span class="tnc-btn" @click.prevent="showTnC = true">{{ $t('tradingTools.condition.link') }}</span>
                        </template>
                      </i18n>
                    </el-checkbox>
                  </el-form-item>
                  <br />
                  <el-button
                    class="bg-primary"
                    @click="onSubmit"
                    v-html="proTpPremiumReplacerPartial($t('tradingTools.condition.unlock'), 2)"
                    data-testid="unlock"
                  ></el-button>
                </el-form>
              </div>
            </el-card>

            <!-- PRO TRADER TOOLS & TUTORIALS -->
            <div class="bottom_box">
              <div class="box">
                <div class="title">{{ $t('menu.MARKET_BUZZ') }}</div>
                <div class="tip">{{ $t('tradingTools.MARKET_BUZZ') }}</div>
                <div class="img">
                  <img src="@/assets/images/tradingTools/MarketBuzz.png" alt="" />
                </div>
              </div>
              <div class="box">
                <div class="title">{{ $t('menu.FEAT_FX') }}</div>
                <div class="tip">{{ $t('tradingTools.FEAT_FX') }}</div>
                <div class="img">
                  <img src="@/assets/images/tradingTools/FeaturedIdeas.png" alt="" />
                </div>
              </div>
              <div class="box">
                <div class="title">{{ $t('menu.ECON_CALENDAR') }}</div>
                <div class="tip">{{ $t('tradingTools.ECON_CALENDAR') }}</div>
                <div class="img">
                  <img src="@/assets/images/tradingTools/EconomicCalendar.png" alt="" />
                </div>
              </div>
              <div class="box">
                <div class="title">{{ $t('menu.FX_IDEAS') }}</div>
                <div class="tip">{{ $t('tradingTools.FX_IDEAS') }}</div>
                <div class="img">
                  <img src="@/assets/images/tradingTools/AnalystViews.png" alt="" />
                </div>
              </div>
              <div class="box">
                <div class="title">{{ $t('menu.ALPHA_GENERATION') }}</div>
                <div class="tip">{{ $t('tradingTools.ALPHA_GENERATION') }}</div>
                <div class="img">
                  <img src="@/assets/images/tradingTools/AlphaGeneration.png" alt="" />
                </div>
              </div>
              <div class="box">
                <div class="title">{{ $t('menu.MARKET_MASTERS') }}</div>
                <div class="tip">{{ $t('tradingTools.MARKET_MASTERS') }}</div>
                <div class="img">
                  <img src="@/assets/images/tradingTools/MarketMasters.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <!-- insert iframes -->
          <div v-else>
            <iframe v-if="path" title="" border="0" :src="path"></iframe>
            <div v-else>
              <component :is="currentComponent" />
            </div>
          </div>
        </idPoaLimit>
      </div>
    </div>

    <LockPremiumTradingTools v-if="lockPremiumTools" />

    <!-- policy dialog -->
    <el-dialog  
      :visible.sync="showTnC"
      top="90px"
      @close="showTnC = false"
      :close-on-click-modal="true"
      center
    >
      <div class="policy-title-wrap">
        <h3 class="policy-title">{{ $t('tradingTools.policy.title') }}</h3>
        <p class="policy-subtitle">{{ $t('tradingTools.policy.subTitle') }}</p>
      </div>
      <div class="policy-content">
        <ul class="policy-term">
          <li class="tnc-text">{{ $t('tradingTools.policy.term1') }}</li>
          <li>{{ $t('tradingTools.policy.term2') }}</li>
          <li>
            <p>{{ $t('tradingTools.policy.term3') }}</p>
            <ul class="policy-term-sub">
              <li>{{ $t('tradingTools.policy.term3Sub1') }}</li>
              <li>{{ $t('tradingTools.policy.term3Sub2') }}</li>
              <li>{{ $t('tradingTools.policy.term3Sub3') }}</li>
            </ul>
          </li>
          <li>{{ $t('tradingTools.policy.term4') }}</li>
          <li>{{ $t('tradingTools.policy.term5') }}</li>
          <li>{{ $t('tradingTools.policy.term6') }}</li>
          <li>{{ $t('tradingTools.policy.term7') }}</li>
          <li>{{ $t('tradingTools.policy.term8') }}</li>
          <li>{{ $t('tradingTools.policy.term9') }}</li>
        </ul>

        <ul class="policy-condition">
          <li>{{ $t('tradingTools.policy.condition1') }}</li>
          <li>{{ $t('tradingTools.policy.condition2') }}</li>
          <li>{{ $t('tradingTools.policy.condition3') }}</li>
          <li>{{ $t('tradingTools.policy.condition4') }}</li>
          <li>{{ $t('tradingTools.policy.condition5') }}</li>
        </ul>
      </div>
      <div slot="footer">
        <el-button class="bg-primary close-dialog-btn" @click="showTnC = false">
          {{ $t('register.btn.back') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/mixins/proTools/tradingTool';
import idPoaMixin from '@/mixins/idPoa';
import asicMixin from '@/mixins/proTools/asicReplacer';
import lockPremiumToolsMixin from '@/mixins/proTools/lockPremiumTools';
import idPoaLimit from '@/components/idPoa/Limit';
import LockPremiumTradingTools from '@/components/LockPremiumTradingTools';
import AlphaGeneration from '@/components/tradingTools/AlphaGeneration';
import MarketMasters from '@/components/tradingTools/MarketMasters.vue'

export default {
  name: 'TradingTools',
  mixins: [mixin, idPoaMixin, asicMixin, lockPremiumToolsMixin],
  components: { idPoaLimit, LockPremiumTradingTools, AlphaGeneration, MarketMasters }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/tradingTools.scss';
</style>
