<template>
  <vDialog :visible.sync="visible" :visibleFooter="false" :enableCloseOnBackground="false">
    <p v-html="$t('lock.premium.trading.tools.description')"></p>
  </vDialog>
</template>

<script>
import vDialog from '@/components/home/vDialog';

export default {
  components: { vDialog },
  data() {
    return {
      visible: true,
      count: 3,
      countDownInterval: null
    };
  },
  mounted() {
    this.startToCountDown();
  },
  destroyed() {
    clearInterval(this.countDownInterval);
  },
  methods: {
    startToCountDown() {
      this.countDownInterval = setInterval(() => {
        if (this.count > 0) {
          this.count--;
        } else {
          this.$router.push('/home');
        }
      }, 1000);
    }
  }
};
</script>
