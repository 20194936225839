import { apiProCheckUnlocked, apiTcmktCheckDeposit, apiTcmktCheckTnc, apiTcmktTncAccept } from '@/resource'
import { checkAgreedTNC } from '@/util/validation'

export default {
  data() {
    return {
      needDeposit: false,
      needAccept: false,
      form: {
        checked: false,
      },
      rule: {
        checked: [{ validator: checkAgreedTNC, trigger: 'change' }],
      },
      path: '',
      showTnC: false,
    }
  },
  async mounted() {
    if (this.$refs.idPoaLimit.showPage) {
      const unlocked = await apiProCheckUnlocked()
      const deposit = await apiTcmktCheckDeposit()
      const checkTnc = await apiTcmktCheckTnc()
      this.needUnlock = !unlocked.data.data.tcMktUnlocked
      this.needDeposit = !deposit.data.data
      this.needAccept = !checkTnc.data.data

      if ((!this.needDeposit && !this.needAccept) || !this.needUnlock) this.getUrl()
    }
  },
  methods: {
    onSubmit() {
      this.$refs['tradingToolForm'].validate(valid => {
        if (valid) {
          apiTcmktTncAccept().then(resp => {
            if (resp.data.data) {
              this.needAccept = false
              this.getUrl()
            }
          })
        } else return false
      })
    },
    getUrl() {
      this.$store.dispatch('common/actionTradingToolUrl').then(resp => {
        if (resp) this.setPath()
      })
    },
    setPath() {
      this.path = this.tradingToolUrl[this.$route.name]
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.tradingToolUrl) this.setPath()
      },
    },
  },
  computed: {
    tradingToolUrl() {
      return this.$store.state.common.tradingToolUrl
    },
    restrictTradingTool() {
      return (this.needDeposit && this.needUnlock) || this.needAccept
    },
    showClass() {
      return this.restrictTradingTool && this.$refs.idPoaLimit.showPage
    },
    currentComponent() {
      let componentName = null
      const routeName = this.$route.name
      switch (routeName) {
        case 'ALPHA_GENERATION':
          componentName = 'AlphaGeneration'
          break
        case 'MARKET_MASTERS':
          componentName = 'MarketMasters'
          break
        default:
          break
      }

      return componentName
    },
  },
}
