export default {
  computed: {
    lockPremiumTools() {
      return (
        this.isPageVisibleByTradableAccounts &&
        (this.$store.state.common.pendingQuestionnaireCompletion || this.$store.state.common.registerStep <= 4)
      )
    },
    isPageVisibleByTradableAccounts() {
      return this.$platform.isPageVisibleByTradableAccounts(this.regulator, this.$route.name)
    },
  },
}
