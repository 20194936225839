<template>
  <el-card>
    <div class="button-wrap">
      <el-button
        v-for="(item, i) in videoLevelMaper"
        :key="'level' + i"
        :type="level === item.videoLevel ? 'primary' : ''"
        class="action-button"
        @click="level = item.videoLevel"
      >
        {{ $t('common.button.' + item.videoLevel) }}
      </el-button>
    </div>
    <div v-for="(item, i) in videoLevelMaper" :key="'content' + i">
      <div v-if="level === item.videoLevel">
        <div :data-dyntube-key="item.dyntubeKey"></div>
      </div>
    </div>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      level: 'beginner',
      videoLevelMaper: [
        {
          videoLevel: 'beginner',
          dyntubeKey: 'bsW6mD406pMVSgELrJA'
        },
        {
          videoLevel: 'intermediate',
          dyntubeKey: 'WWivCCopAkKXOUSaP4NPhg'
        },
        {
          videoLevel: 'advanced',
          dyntubeKey: 'VYGvK8QHEihlX9o4L1og'
        }
      ]
    };
  },
  mounted() {
    if (!document.getElementById('toEmbedVideo')) {
      let videoScript = document.createElement('script');
      videoScript.setAttribute('src', 'https://embed.dyntube.com/v1.0/dyntube.js');
      videoScript.setAttribute('id', 'toEmbedVideo');
      document.head.appendChild(videoScript);
    }
  }
};
</script>

<style lang="scss" scoped>
.button-wrap {
  margin-top: 24px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button {
  & +& {
    margin-left: 24px;
  }
}

@media (max-width: 768px) {
  .button-wrap {
    flex-direction: column;
  }
  /deep/ .el-button:not(.el-button--mini):not(.el-button--small) {
    min-width: 100%;
  }
  .action-button {
    & +& {
      margin: 16px 0 0 0;
    }
  }
}
</style>
