<template>
  <div class="content_box">
    <div class="inner_max">
      <h3>{{ $t('tradingTools.alphaGeneration.intro') }}</h3>
      <el-card>
        <div class="card-text">{{ $t('tradingTools.alphaGeneration.introDesc') }}</div>

        <div class="container">
          <div class="box">
            <div class="title">{{ $t('tradingTools.alphaGeneration.analystViews.title') }}</div>
            <div class="image-wrapper">
              <img src="@/assets/images/tradingTools/AnalystViews2.png" alt="AnalystViews_image" />
            </div>
            <div class="tip">{{ $t('tradingTools.alphaGeneration.analystViews.desc') }}</div>
          </div>
          <div class="box">
            <div class="title">{{ $t('tradingTools.alphaGeneration.adaptiveCandlesticks.title') }}</div>
            <div class="image-wrapper">
              <img src="@/assets/images/tradingTools/AdaptiveCandlesticks.png" alt="AdaptiveCandlesticks_image" />
            </div>
            <div class="tip">{{ $t('tradingTools.alphaGeneration.adaptiveCandlesticks.desc') }}</div>
          </div>
          <div class="box">
            <div class="title">{{ $t('tradingTools.alphaGeneration.adc.title') }}</div>
            <div class="image-wrapper">
              <img src="@/assets/images/tradingTools/ADC.png" alt="ADC_image" />
            </div>
            <div class="tip">{{ $t('tradingTools.alphaGeneration.adc.desc') }}</div>
          </div>
        </div>

        <div class="action-btn">
          <a href="https://global.tradingcentral.com/marketing/GettingStarted/AlphaGen/Indicators.html" target="_blank">
            <el-button class="el-button el-button--primary">{{ $t('tradingTools.alphaGeneration.button.guid') }}</el-button>
          </a>
        </div>
      </el-card>
    </div>

    <div class="inner_max">
      <h3>{{ $t('tradingTools.alphaGeneration.install') }}</h3>
      <el-card>
        <ul class="terms-warpper">
          <li>
            <div class="terms">
              <img class="dot" src="@/assets/images/tradingTools/dotOne.png" alt="" />
              <p class="text">{{ $t('tradingTools.alphaGeneration.installSteps.one') }}</p>
            </div>
          </li>
          <li>
            <div class="terms">
              <img class="dot" src="@/assets/images/tradingTools/dotTwo.png" alt="" />
              <p class="text">{{ $t('tradingTools.alphaGeneration.installSteps.two') }}</p>
            </div>
          </li>
          <li>
            <div class="terms">
              <img class="dot" src="@/assets/images/tradingTools/dotThree.png" alt="" />
              <p class="text">{{ $t('tradingTools.alphaGeneration.installSteps.three') }}</p>
            </div>
          </li>
          <li>
            <div class="terms">
              <img class="dot" src="@/assets/images/tradingTools/dotFour.png" alt="" />
              <p class="text">{{ $t('tradingTools.alphaGeneration.installSteps.four') }}</p>
            </div>
          </li>
          <li>
            <div class="terms">
              <img class="dot" src="@/assets/images/tradingTools/dotFive.png" alt="" />
              <p class="text">{{ $t('tradingTools.alphaGeneration.installSteps.five') }}</p>
            </div>
          </li>
        </ul>

        <div class="action-btn">
          <a :href="downloadLink" target="_blank">
            <el-button class="el-button el-button--primary">{{ $t('tradingTools.alphaGeneration.button.download') }}</el-button>
          </a>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { countryCodeEnum } from '@/constants/country';

export default {
  name: "AlphaGeneration",
  computed: {
    countryCode() {
      return Number(this.$store.state.common.countryCode);
    },
    downloadLink() {
      let link = '';
      switch (this.countryCode) {
        case countryCodeEnum.CHINA:
          link = 'https://mt.tradingcentral.cn/download';
          break;
        default:
          link = 'https://mt.tradingcentral.com/download';
          break;
      }
      return link;
    },
    userGuideLink() {
      let link = '';
      switch (this.countryCode) {
        case countryCodeEnum.CHINA:
          link = 'https://global.tradingcentral.com/marketing/GettingStarted/AlphaGenCH/index.html';
          break;
        default:
          link = 'https://global.tradingcentral.com/marketing/GettingStarted/GettingstartedwithMT4/index.html';
          break;
      }
      return link;
    }
  }
}
</script>

<style lang="scss" scoped>
.inner_max {
  & +& {
    margin-top: 56px;
  }
}

.card-text {
  text-align: center;
  color: $white;
  line-height: 1.667;
  font-size: 18px;
  margin-bottom: 36px;
  margin-top: 44px;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-column-gap: 1%;
  grid-row-gap: 2%;

  .box {
    border-radius: 8px;
    background-color: $component-bg-primary;
    padding: 25px;
    color: $white;
    min-height: 200px;

    .title {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 15px;
      text-align: center;
    }
    .tip {
      line-height: 1.667;
      min-height: 70px;
      text-align: center;
    }

    .image-wrapper {
      margin-bottom: 15px;
      padding: 16px;
      background: radial-gradient(ellipse, rgba(86,172,245,1) 0%, rgba(31,34,47,1) 74%);
    }

    img {
      object-fit: cover;
      height: 200px;
      width: 100%;
    }
  }
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}

.terms-warpper {
  padding-top: 44px;
  padding-left: 44px;
  padding-right: 44px;
  margin-bottom: 44px;
}

.terms {
  display: flex;
  align-items: center;
  justify-items: start;
  margin-bottom: 36px;
  font-size: 16px;

  .dot {
    width: 24px;
    height: 24px;
    padding: 2px;
    align-self: start;
    margin-right: 16px;
  }

  .text {
    line-height: 1.7;
  }
}

@media (max-width: 1280px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
    grid-row-gap: 15px;
  }

  .terms-warpper {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .terms-warpper {
    padding-left: 12px;
    padding-right: 12px;
  }

  .container {
    .box {
      img {
        height: auto;
      }
    }
  }
}


</style>
